const state = {
  pickupDate: getFormattedDate(new Date()),
  dropoffDate: getFormattedDate(new Date()),
};

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const mutations = {
  setPickupDate(state, date) {
    state.pickupDate = date;
  },
  setDropoffDate(state, date) {
    state.dropoffDate = date;
  },
};

const actions = {
  updatePickupDate({ commit, state }, date) {
    if (state.dropoffDate && date > state.dropoffDate) {
      return "invalid-date"; // 오류 식별자 반환
    }
    commit("setPickupDate", date);
    return null; // 오류가 없음
  },
  updateDropoffDate({ commit, state }, date) {
    if (state.pickupDate && date < state.pickupDate) {
      return "invalid-date"; // 오류 식별자 반환
    }
    commit("setDropoffDate", date);
    return null; // 오류가 없음
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
