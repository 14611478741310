import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/HomeView.vue"),
    meta: {
      showNavbar: true,
      showFooter: true,
    },
  },
  {
    path: "/listing",
    name: "Listing",
    component: () => import("@/views/listing/ListingView.vue"),
    meta: {
      showMainNavbar: true,
      showFooter: true,
    },
  },
  {
    path: "/reserve",
    name: "Reserve",
    component: () => import("@/views/reserve/ReserveView.vue"),
    beforeEnter: (to, from, next) => {
      const toStep = parseInt(to.query.step);
      const fromStep = parseInt(from.query.step);

      // 주소창에서 직접 step=2 또는 step=3을 입력한 경우, 또는 step=3에서 step=2로 이동하려는 경우 step=1로 리다이렉트
      if (
        ((toStep === 2 || toStep === 3) && from.name === null) ||
        (toStep === 2 && fromStep === 3)
      ) {
        next({
          path: "/listing",
        });
        return;
      }

      // 쿼리 매개변수가 없을 경우 기본값을 설정
      if (!to.query.choose && !to.query.step) {
        next({
          path: "/listing",
        });
        return;
      }

      next();
    },
    meta: {
      showMainNavbar: true,
      showFooter: true,
    },
  },
  {
    path: "/map_booking",
    name: "Map/Booking",
    component: () => import("@/views/locationMap/LocationMapView.vue"),
    meta: {
      showMainNavbar: true,
      showFooter: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/contact/ContactView.vue"),
    meta: {
      showMainNavbar: true,
      showFooter: true,
    },
  },
  {
    path: "/policy/:policyType?",
    name: "Policy",
    component: () => import("@/views/policy/PolicyView.vue"),
  },
  {
    path: "/reserve-end",
    name: "reserveResult",
    component: () => import("@/views/reserveEnd/reserveEnd.vue"),
    meta: {
      showMainNavbar: true,
      showFooter: true,
    },
  },
  {
    path: "/personalinformation",
    name: "personalInformationProcessingPolicy",
    component: () => import("@/views/personPolicy.vue"),
  },
  {
    path: "/terms_conditions",
    name: "termsAndConditions",
    component: () => import("@/views/termsAndConditions.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
