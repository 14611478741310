const state = {
  user: null,
};

const mutations = {
  SET_USER(state, userData) {
    state.user = userData;
  },
};

const actions = {
  loginUser({ commit }, userData) {
    commit("SET_USER", userData);
  },
};

const getters = {
  getUser: (state) => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
