const state = {
  locale: localStorage.getItem("selectedLocale") || "en",
};

const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale;
    localStorage.setItem("selectedLocale", locale);
  },
};

const actions = {
  changeLocale({ commit }, locale) {
    switch (locale) {
      case "en":
        commit("SET_LOCALE", "en");
        break;
      case "ko":
        commit("SET_LOCALE", "ko");
        break;
      case "zh":
        commit("SET_LOCALE", "zh");
        break;
      case "ja":
        commit("SET_LOCALE", "ja");
        break;
      case "vi":
        commit("SET_LOCALE", "vi");
        break;
      case "th":
        commit("SET_LOCALE", "th");
        break;
      default:
        commit("SET_LOCALE", "en");
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
