import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import locales from "./modules/locales";
import areaCode from "./modules/areaCode";
import homeDate from "./modules/homeDate";
import reservationResult from "./modules/reservationResult";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    reservationResult,
    homeDate,
    areaCode,
    auth,
    locales,
  },
});
