<template>
  <div>
    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>
    <header class="site-navbar site-navbar-target" role="banner">
      <div class="container">
        <div
          class="row align-items-center align-content-between position-relative"
        >
          <div class="col-lg-5 col-12 d-flex justify-content-between">
            <div class="site-logo">
              <router-link to="/"
                ><img src="../../assets/images/logo.png" alt="Image"
              /></router-link>
            </div>
            <span class="d-inline-block d-lg-none"
              ><a class="site-menu-toggle js-menu-toggle py-6"
                ><span class="icon-menu h3 text-black"></span></a
            ></span>
          </div>
          <div class="col-lg-7 col-md-6 col-12 text-right">
            <div class="row align-content-between align-items-center">
              <nav
                class="site-navigation text-right ml-auto d-none d-lg-block"
                role="navigation"
              >
                <ul class="site-menu main-menu js-clone-nav ml-auto">
                  <li>
                    <router-link to="/" class="nav-link">{{
                      $t("index.1")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/listing" class="nav-link">{{
                      $t("index.2")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/map_booking" class="nav-link">{{
                      $t("index.3")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/contact" class="nav-link">{{
                      $t("index.4")
                    }}</router-link>
                  </li>
                </ul>
              </nav>
              <div class="col-lg-3 col-sm-6">
                <select
                  id="languageSelect"
                  class="custom-select form-control"
                  v-model="selectedLocale"
                  v-on:change="changeLo($event)"
                >
                  <option value="en">English</option>
                  <option value="ko">한국어</option>
                  <option value="zh">中文</option>
                  <option value="ja">日本語</option>
                  <option value="vi">Tiếng Việ</option>
                  <option value="th">ภาษาไทย</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div :class="['hero', 'inner-page', pageClass]">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-5">
            <div class="intro">
              <h1>
                <strong>{{ pageTitle }}</strong>
              </h1>
              <div class="custom-breadcrumbs">
                <router-link to="/">{{ $t("index.1") }}</router-link
                ><span class="mx-2">/</span>
                <template v-if="breadcrumbs.length">
                  <router-link
                    v-for="(breadcrumb, index) in breadcrumbs"
                    :key="index"
                    :to="breadcrumb.path"
                    >{{ breadcrumb.title }}</router-link
                  >
                  <span class="mx-2">/</span>
                </template>
                <strong>{{ pageTitle }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const toggleMenu = function (e) {
      var $this = $(this);
      e.preventDefault();

      if ($("body").hasClass("offcanvas-menu")) {
        $("body").removeClass("offcanvas-menu");
        $this.removeClass("active");
      } else {
        $("body").addClass("offcanvas-menu");
        $this.addClass("active");
      }
    };

    $(".js-clone-nav").each(function () {
      var $this = $(this);
      $this
        .clone()
        .attr("class", "site-nav-wrap")
        .appendTo(".site-mobile-menu-body");
    });

    $("body").on("click", ".js-menu-toggle", toggleMenu);

    this.$once("hook:beforeDestroy", () => {
      $("body").off("click", ".js-menu-toggle", toggleMenu);
    });
    this.$i18n.locale = this.$store.state.locales.locale;
    this.pageTitle = this.getTitleByRoute(this.$route.path, this.currentLocale);
    this.breadcrumbs = this.getBreadcrumbsByRoute(
      this.$route.path,
      this.currentLocale,
    );
  },
  data() {
    return {
      pageClass: "listing_bg",
      selectedLocale: this.$store.state.locales.locale || "en",
      pageTitle: "", // 초기값 설정
      breadcrumbs: [], // 초기값 설정
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        // pageTitle과 breadcrumbs 값을 업데이트
        this.pageTitle = this.getTitleByRoute(newVal, this.currentLocale);
        this.breadcrumbs = this.getBreadcrumbsByRoute(
          newVal,
          this.currentLocale,
        );
      },
    },
    currentLocale: {
      immediate: true,
      handler(newLocale) {
        this.pageTitle = this.getTitleByRoute(this.$route.path, newLocale);
        this.breadcrumbs = this.getBreadcrumbsByRoute(
          this.$route.path,
          newLocale,
        );
      },
    },
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.locale;
    },
  },
  methods: {
    getTitleByRoute(route, locale) {
      switch (route) {
        case "/listing":
          return this.$t("index.2");
        case "/map_booking":
          return this.$t("index.3");
        case "/contact":
          return this.$t("index.4");
        case "/reserve":
          return this.$t("index.77");
        case "/reserve-end":
          return this.$t("index.415");
        default:
          return this.$t("index.1");
      }
    },
    getBreadcrumbsByRoute(route, locale) {
      if (route === "/reserve") {
        return [{ path: "/listing", title: this.$t("index.2") }];
      }
      return [];
    },
    changeLo(event) {
      this.$store.dispatch("locales/changeLocale", event.target.value);
      this.$i18n.locale = this.$store.state.locales.locale;
    },
  },
};
</script>
