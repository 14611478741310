const state = {
  reservationResponse: null,
};

const mutations = {
  SET_RESERVATION_RESPONSE(state, payload) {
    state.reservationResponse = payload;
  },
};

const actions = {
  saveReservationResponse({ commit }, response) {
    commit("SET_RESERVATION_RESPONSE", response);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
