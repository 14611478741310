const state = {
  selectedArea: "0002",
};

const mutations = {
  SET_SELECTED_AREA(state, area) {
    state.selectedArea = area;
  },
};

const actions = {
  setSelectedArea({ commit }, area) {
    commit("SET_SELECTED_AREA", area);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
