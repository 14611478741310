import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import i18n from "./i18n";

import $ from "jquery";
window.$ = window.jQuery = $;

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/bootstrap-datepicker.css";
import "./assets/scss/style.scss";
import "./assets/fonts/icomoon/style.css";
import "./assets/js/bootstrap-datepicker.min.js";

if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mocks/browser");

  worker.start();
}

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
