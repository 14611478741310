<template>
  <div class="site-wrap" id="home-section">
    <NavBar v-if="$route.meta.showNavbar" />
    <MainNavBar v-else-if="$route.meta.showMainNavbar" />
    <router-view />
    <FooterBar v-if="$route.meta.showFooter" />
  </div>
</template>

<script>
import NavBar from "./components/layouts/NavBar.vue";
import MainNavBar from "./components/layouts/MainNavBar.vue";
import FooterBar from "./components/layouts/FooterBar.vue";

export default {
  components: {
    NavBar,
    MainNavBar,
    FooterBar,
  },
};
</script>
