<template>
  <div>
    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>
    <header class="site-navbar site-navbar-target" role="banner">
      <div class="container">
        <div
          class="row align-items-center align-content-between position-relative"
        >
          <div class="col-lg-5 col-12 d-flex justify-content-between">
            <div class="site-logo">
              <router-link to="/"
                ><img src="../../assets/images/logo.png" alt="Image" />
              </router-link>
            </div>
            <span class="d-inline-block d-lg-none"
              ><a class="site-menu-toggle js-menu-toggle py-6"
                ><span class="icon-menu h3 text-black"></span></a
            ></span>
          </div>
          <div class="col-lg-7 col-md-6 col-12 text-right">
            <div class="row align-content-between align-items-center">
              <div class="col-lg-9 col-sm-6">
                <nav
                  class="site-navigation text-right ml-auto d-none d-lg-block"
                  role="navigation"
                >
                  <ul class="site-menu main-menu js-clone-nav ml-auto">
                    <li>
                      <router-link to="/" class="nav-link">{{
                        $t("index.1")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/listing" class="nav-link">{{
                        $t("index.2")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/map_booking" class="nav-link">{{
                        $t("index.3")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/contact" class="nav-link">{{
                        $t("index.4")
                      }}</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-lg-3 col-sm-6">
                <select
                  id="languageSelect"
                  class="custom-select form-control"
                  v-model="selectedLocale"
                  v-on:change="changeLo($event)"
                >
                  <option value="en">English</option>
                  <option value="ko">한국어</option>
                  <option value="zh">中文</option>
                  <option value="ja">日本語</option>
                  <option value="vi">Tiếng Việ</option>
                  <option value="th">ภาษาไทย</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLocale: this.$store.state.locales.locale || "en",
    };
  },
  mounted() {
    const toggleMenu = function (e) {
      var $this = $(this);
      e.preventDefault();

      if ($("body").hasClass("offcanvas-menu")) {
        $("body").removeClass("offcanvas-menu");
        $this.removeClass("active");
      } else {
        $("body").addClass("offcanvas-menu");
        $this.addClass("active");
      }
    };

    $(".js-clone-nav").each(function () {
      var $this = $(this);
      $this
        .clone()
        .attr("class", "site-nav-wrap")
        .appendTo(".site-mobile-menu-body");
    });

    $("body").on("click", ".js-menu-toggle", toggleMenu);

    this.$once("hook:beforeDestroy", () => {
      $("body").off("click", ".js-menu-toggle", toggleMenu);
    });
    this.$i18n.locale = this.$store.state.locales.locale;
  },
  watch: {
    "$store.state.locales.locale": function (newLocale) {
      this.selectedLocale = newLocale;
    },
  },
  methods: {
    changeLo(event) {
      this.$store.dispatch("locales/changeLocale", event.target.value);
      this.$i18n.locale = this.$store.state.locales.locale;
    },
  },
};
</script>
